import { createRouter, createWebHistory } from 'vue-router'
import StorageHomeView from '@/views/StorageHomeView'
import LoginView from "@/views/auth/LoginView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: StorageHomeView
  },
  {
    path: '/storage/:bucket',
    name: 'storage-home',
    component: StorageHomeView
  },
  {
    path: '/storage/:bucket/:folder',
    name: 'storage-folder',
    component: StorageHomeView
  },
  {
    path: '/storage/:bucket/:folder/:file',
    name: 'storage-file',
    component: StorageHomeView
  },
  {
    path: '/auth/login',
    name: 'login',
    component: LoginView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
